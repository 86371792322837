import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'


export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t(''),
    items: [
      {
        label: t(''),
        href: 'https://docs.pancakeswap.finance/contact-us',
      },
      {
        label: t(''),
        href: 'https://docs.pancakeswap.finance/brand',
      },
      {
        label: t(''),
        href: 'https://medium.com/pancakeswap',
      },
      {
        label: t(''),
        href: 'https://docs.pancakeswap.finance/contact-us/telegram',
      },
      {
        label: t(' '),
        href: 'https://docs.pancakeswap.finance/tokenomics/cake',
      },
      {
        label: '',
      },
      {
        label: t(''),
        href: 'https://pancakeswap.creator-spring.com/',
        isHighlighted: true,
      },
    ],
  },
  {
    label: t(''),
    items: [
      {
        label: t(''),
        href: 'https://docs.pancakeswap.finance/contact-us/customer-support',
      },
      {
        label: t(''),
        href: 'https://docs.pancakeswap.finance/help/troubleshooting',
      },
      {
        label: t(''),
        href: 'https://docs.pancakeswap.finance/get-started',
      },
    ],
  },
  {
    label: t(''),
    items: [
      {
        label: '',
        href: 'https://github.com/pancakeswap',
      },
      {
        label: t(''),
        href: 'https://docs.pancakeswap.finance',
      },
      {
        label: t(' '),
        href: 'https://docs.pancakeswap.finance/code/bug-bounty',
      },
      {
        label: t(''),
        href: 'https://docs.pancakeswap.finance/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited',
      },
      {
        label: t(''),
        href: 'https://docs.pancakeswap.finance/hiring/become-a-chef',
      },
    ],
  },
]
