import { VaultKey } from 'state/types'
import tokens, { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

export const vaultPoolConfig = {
  [VaultKey.CakeVault]: {
    name: '',
    description: ' ',
    autoCompoundFrequency: 1,
    gasLimit: 1,
    tokenImage: {
      primarySrc: ``,
      secondarySrc: `` ,
    },
  },
  [VaultKey.IfoPool]: {
    name: ' ',
    description: ' ',
    autoCompoundFrequency: 1,
    gasLimit: 1,
    tokenImage: {
      primarySrc: ``,
      secondarySrc: ``,
    },
  },
} as const

console.log(vaultPoolConfig);

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.silva,
    earningToken: serializedTokens.silva,
    contractAddress: {
      97: '',
      56: '0x68b5edb385b59e30a7a7db1e681a449e94df0213',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
 
  {
    sousId: 1,
    stakingToken: serializedTokens.silva,
    earningToken: serializedTokens.busd,
    contractAddress: {
      97: '',
      56: '0x94dF70fE48Cb4f9ccC7BfC0f4d756d7b125168Ef',
    },
    poolCategory: PoolCategory.BINANCE,
    harvest: true,
    tokenPerBlock: '0.5',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 2,
    stakingToken: serializedTokens.silva,
    earningToken: serializedTokens.shib,
    contractAddress: {
      97: '',
      56: '0x15D90Ad7E7e3F4b557fB2b4a703141CAF194C3B6',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0041',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 3,
    stakingToken: serializedTokens.silva,
    earningToken: serializedTokens.ada,
    contractAddress: {
      97: '',
      56: '0xE5Fc1BAfD690f691bC4afbB245F8335f29D6e697',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0041',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 4,
    stakingToken: serializedTokens.silva,
    earningToken: serializedTokens.matic,
    contractAddress: {
      97: '',
      56: '0xE5Fc1BAfD690f691bC4afbB245F8335f29D6e697',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0041',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 5,
    stakingToken: serializedTokens.silva,
    earningToken: serializedTokens.raca,
    contractAddress: {
      97: '',
      56: '0x4564259481cf15fDa04170129067fB2ca8a1e24c',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0041',
    sortOrder: 999,
    isFinished: true,
  },
  // {
  //   sousId: 19,
  //   stakingToken: serializedTokens.silva,
  //   earningToken: serializedTokens.bnb,
  //   contractAddress: {
  //     97: '',
  //     56: '0x326D754c64329aD7cb35744770D56D0E1f3B3124',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '0.0041',
  //   sortOrder: 999,
  //   isFinished: true,
  // },
  
]

export default pools
